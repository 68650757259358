<template>
  <div style="background-color: #F6F6F6" class="rounded px-2 pb-4">
    <div class="mt-4 " style="padding-left: 10px">
      <ValidationProvider name="Nhận xét " :rules="{required :skillCurrent.score>=2,min: skillCurrent.score < 2 ? 0 : 50}"
                          v-slot="{ errors,classes }"
                          :vid=" 'nhan_xet'">
        <p>Nhận xét cụ thể, dẫn chứng: <span style="color: red">*</span></p>
        <el-input
            :autosize="{ minRows: 4, maxRows: 4 }"
            type="textarea"
            placeholder="Nhập nhận xét"
            v-model="skillCurrent.specificComments"
        />
        <div class="fv-plugins-message-container">
          <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputSpecificComments",
  props: {
    skillCurrent:{
      type: Object,
      default(){
        return {};
      }
    }
  }
}
</script>

<style scoped>

</style>