<template>
  <div style="background-color: #F8F8F8" class="rounded px-2 pb-4" >
    <div class=" d-lg-block justify-content-between">
      <div class="   py-0 d-flex align-items-center  ">
        <p class="mb-0 text-dark badge badge-danger" style="font-size: 13px; background-color: #FF9393;border-radius: 3px ">
          {{ criteria.nameDetailSkill }}</p>
      </div>
      <ValidationProvider name="Điểm" rules="required" v-slot="{ errors,classes }"
                          :vid="criteria.id+'diem'">
        <div class="d-xl-flex d-block mt-4 align-items-center justify-content-between">
          <div class="d-xl-flex d-block">
            <p class="mt-4">Điểm tiêu chí: <span class="text-danger">*</span></p>
            <el-select class="m-2" placeholder="Chọn điểm" size="small"
                       v-model=" criteria.scoreDetail" @change="handleComment" style="width: 130px;">
              <el-option
                  v-for="item in criteria.listMark"
                  :key="item"
                  :label="item"
                  :value="item"
              />
            </el-select>
          </div>

          <div class="d-xl-flex  h-100">
            <p class="mb-0 text-white badge badge-danger" style="font-size: 13px; background-color: #287EFF;
; height: 50%; cursor: pointer; border-radius: 3px" @click="openModalBarem()">
              Chấm theo barem</p>
          </div>

        </div>
        <div class="fv-plugins-message-container">
          <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
        </div>
      </ValidationProvider>
    </div>
    <div class="mt-4">
      <p >Nhận xét tự động:</p>
      <el-input
          :value="criteria.commentAuto"
          :autosize="{ minRows: 2, maxRows: 4}"
          type="textarea"
          placeholder=" Chọn điểm để hiển thị nhận xét tự động"
          readonly

      />
    </div>
    <hr>
    <ModalBarem :id='id' :detailSkill="criteria" @handleComment="handleComment()"></ModalBarem>
  </div>
</template>

<script>

import {SPEAKING_CRITERIAES} from "@/core/option/testInputOption";
import ModalBarem from "@/view/pages/test-input/components/ComponentMarkTheExam/ModalBarem.vue";

export default {
  name: "ReviewSkill",
  components: {ModalBarem},
  props: {
    criteria: {
      type: Object,
      default() {
        return {}
      }
    },
    flagMarkIs0: {
      type: Boolean,
      default() {
        return false
      }
    },
    id: {
      type: String,
      default() {
        return ''
      }
    },
    skillCurrent: {
      type: Object,
      default() {
        return {}
      }
    },
    allCriteria:{
      type: Array,
      default() {
        return []
      }
    }

  },
  data() {
    return {
      point: null,

    }
  },
  mounted() {
    this.criteria.comment = ""
    this.criteria.autoComment = ""
    this.point = this.criteria.scoreDetail
  },
  methods: {
    handleComment() {
      this.criteria.setMark(this.criteria.scoreDetail);
      this.skillCurrent.score = this.criteria.scoreDetail;
      if(this.criteria.scoreDetail <2){
        this.allCriteria.forEach((criteria)=>{
          criteria.setPointAndCommentAutoIs0Or1(this.criteria.scoreDetail)
        })
      }else {
        this.allCriteria.forEach((criteria)=>{
          criteria.clearPointAndCommentAutoIs0Or1()
        })
      }
    },
    openModalBarem() {
      this.$bvModal.show(`ModalBarem${this.id}`)
    }
  },
}
</script>

<style scoped>
*:focus {
  outline: none;
}

</style>